import React from "react"
import * as styles from "./SalesCollaborationToolsLowEngagement.module.scss"
import SalesCollaborationToolsScreen from "../components/SalesCollaborationToolsScreen"
import { Option } from "components/SupplierOrganizationDashboard/LeftSidebar"
import { StatCards } from "../components/StatCards"

export interface Props {
  lowEngagementCount: number
  lowEngagementOrdersCount: number
  lowEngagementUnassignedCount: number
  supplierOrgExternalId: string
  isSsr: boolean
}

const SalesCollaborationToolsLowEngagement = (props: Props) => {
  const cards = [
    {
      title: "Low-engaged facilities",
      subtitle: "0 user logins in last 60 days",
      count: props.lowEngagementCount,
    },
    {
      title: "Open orders",
      subtitle: "Open orders across low-engaged facilities",
      count: props.lowEngagementOrdersCount,
    },
    {
      title: "Unassigned facilities",
      subtitle: "Low-engaged facilities with no sales rep",
      count: props.lowEngagementUnassignedCount,
    },
  ]

  return (
    <SalesCollaborationToolsScreen
      {...props}
      screen={Option.LowEngagement}
      headerContent={
        <>
          <h1>
            <span className={styles.headerText}>Low engagement</span>
          </h1>
          <div className={styles.subheaderText}>
            These facilities haven’t had any user logins in the last 60 days.
            Facilities with zero user logins for over six months have been
            excluded.
          </div>
          <StatCards cards={cards} />
        </>
      }
    />
  )
}

export default SalesCollaborationToolsLowEngagement
